<template>
    <div class="flex-1">
        <div class="flex flex-column flex-auto">
            <div class="surface-section p-5">
                <div class="flex align-items-start flex-column lg:flex-row lg:justify-content-between">
                    <div class="flex align-items-start flex-column md:flex-row">
                        <img src="images/dummy-logo.png" class="mr-5 mb-3 lg:mb-0" style="width:90px;height:90px" />
                        <div>
                            <span class="text-900 font-medium text-3xl">MOTIONspeed Spedition, Transport GmbH, Lauterach</span>
                            <i class="pi pi-star-fill text-2xl ml-4 text-yellow-500"></i>
                            <i class="pi pi-star-fill text-2xl ml-4 text-yellow-500"></i>
                            <i class="pi pi-star-fill text-2xl ml-4 text-yellow-500"></i>
                            <div class="flex align-items-center flex-wrap text-sm">
                                <div class="mr-5 mt-3">
                                    <span class="font-medium text-500">Hauptbetreuer</span>
                                    <div class="text-700 mt-2">Thomas Kräer</div>
                                </div>
                                <div class="mr-5 mt-3">
                                    <span class="font-medium text-500">Ansprechpartner</span>
                                    <div class="text-700 mt-2">Karl Gruber</div>
                                </div>
                                <div class="mr-5 mt-3">
                                    <span class="font-medium text-500">Adresse</span>
                                    <div class="text-700 mt-2">Bundestraße  120, 6923 Lauterach, AT</div>
                                </div>
                                <div class="mr-5 mt-3">
                                    <span class="font-medium text-500">EDV Nummern</span>
                                    <div class="text-700 mt-2">Transport Management System: 9H8765</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3 lg:mt-0">
                        <Button icon="pi pi-bookmark" class="p-button-rounded mr-2"></Button>
                        <Button icon="pi pi-heart" class="p-button-rounded p-button-success mr-2"></Button>
                        <Button icon="pi pi-list" class="p-button-rounded p-button-help"></Button>
                    </div>
                </div>
            </div>
           





            <div class="p-2">
                <div class="grid">





                    <div class="col-12 lg:col-6 xl:col-3">
                        <div class="surface-card shadow-2 p-3 border-1 border-50 border-round">
                            <div class="flex justify-content-between mb-3">
                                <div>
                                    <span class="block text-500 font-medium mb-3">Shipments</span>
                                    <div class="text-900 font-medium text-xl">152</div>
                                </div>
                                <div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
                                    <i class="pi pi-shopping-cart text-blue-500 text-xl"></i>
                                </div>
                            </div>
                            <span class="text-green-500 font-medium">24 neu </span>
                            <span class="text-500">seit letzten Besuch</span>
                        </div>
                    </div>
                    <div class="col-12 lg:col-6 xl:col-3">
                        <div class="surface-card shadow-2 p-3 border-1 border-50 border-round">
                            <div class="flex justify-content-between mb-3">
                                <div>
                                    <span class="block text-500 font-medium mb-3">Revenue</span>
                                    <div class="text-900 font-medium text-xl">€2.100.000</div>
                                </div>
                                <div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width:2.5rem;height:2.5rem">
                                    <i class="pi pi-map-marker text-orange-500 text-xl"></i>
                                </div>
                            </div>
                            <span class="text-green-500 font-medium">%12+ </span>
                            <span class="text-500">seit letzter Woche</span>
                        </div>
                    </div>
                    <div class="col-12 lg:col-6 xl:col-3">
                        <div class="surface-card shadow-2 p-3 border-1 border-50 border-round">
                            <div class="flex justify-content-between mb-3">
                                <div>
                                    <span class="block text-500 font-medium mb-3">Opportunities</span>
                                    <div class="text-900 font-medium text-xl">28441</div>
                                </div>
                                <div class="flex align-items-center justify-content-center bg-cyan-100 border-round" style="width:2.5rem;height:2.5rem">
                                    <i class="pi pi-inbox text-cyan-500 text-xl"></i>
                                </div>
                            </div>
                            <span class="text-green-500 font-medium">520  </span>
                            <span class="text-500">neu registriertd</span>
                        </div>
                    </div>
                    <div class="col-12 lg:col-6 xl:col-3">
                        <div class="surface-card shadow-2 p-3 border-1 border-50 border-round">
                            <div class="flex justify-content-between mb-3">
                                <div>
                                    <span class="block text-500 font-medium mb-3">Angebote</span>
                                    <div class="text-900 font-medium text-xl">152 ungelesen</div>
                                </div>
                                <div class="flex align-items-center justify-content-center bg-purple-100 border-round" style="width:2.5rem;height:2.5rem">
                                    <i class="pi pi-comment text-purple-500 text-xl"></i>
                                </div>
                            </div>
                            <span class="text-green-500 font-medium">85 </span>
                            <span class="text-500">beantwortet</span>
                        </div>
                    </div>

            <div class="col-12 lg:col-6">
                <div class="surface-card shadow-2 border-round p-4 h-full">
                    <div class="flex align-items-center justify-content-between mb-4">
                        <div class="text-900 font-medium text-xl">Benachrichtigungen</div>
                        <div>
                            <Button icon="pi pi-ellipsis-v" class="p-button-text p-button-plain p-button-rounded" @click="$refs.menu2.toggle($event)"></Button>
                            <Menu ref="menu2" :popup="true" :model="items"></Menu>
                        </div>
                    </div>
                    
                    <span class="block text-600 font-medium mb-3">HEUTE</span>
                    <ul class="p-0 mx-0 mt-0 mb-4 list-none">
                        <li class="flex align-items-center py-2 border-bottom-1 surface-border">
                            <div class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
                                <i class="pi pi-dollar text-xl text-blue-500"></i>
                            </div>
                            <span class="text-900 line-height-3">Richard Johanson
                                <span class="text-700">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Alias, libero suscipit. </span>
                            </span>
                        </li>
                        <li class="flex align-items-center py-2">
                            <div class="w-3rem h-3rem flex align-items-center justify-content-center bg-orange-100 border-circle mr-3 flex-shrink-0">
                                <i class="pi pi-download text-xl text-orange-500"></i>
                            </div>
                            <span class="text-700 line-height-3">YoJane Davisorem ipsum dolor sit amet consectetur adipisicing elit. Porro eum sit <span class="text-blue-500 font-medium">2500$</span> has been initiated.</span>
                        </li>
                    </ul>
            
                    <span class="block text-600 font-medium mb-3">GESTERN</span>
                    <ul class="p-0 m-0 list-none">
                        <li class="flex align-items-center py-2 border-bottom-1 surface-border">
                            <div class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
                                <i class="pi pi-dollar text-xl text-blue-500"></i>
                            </div>
                            <span class="text-900 line-height-3">Keyser Wick
                                <span class="text-700">Blanditiis dolorum sit veniam temporibus tempore inventore autem, labore eaque quos error quibusdam ad consequuntur perspiciatis a officia? Illum?<span class="text-blue-500">Note</span></span>
                            </span>
                        </li>
                        <li class="flex align-items-center py-2 border-bottom-1 surface-border">
                            <div class="w-3rem h-3rem flex align-items-center justify-content-center bg-pink-100 border-circle mr-3 flex-shrink-0">
                                <i class="pi pi-question text-xl text-pink-500"></i>
                            </div>
                            <span class="text-900 line-height-3">Jane Davis
                                <span class="text-700">Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro eum sit repellendus magni consequatur illum! </span>
                            </span>
                        </li>
                        <li class="flex align-items-center py-2">
                            <div class="w-3rem h-3rem flex align-items-center justify-content-center bg-green-100 border-circle mr-3 flex-shrink-0">
                                <i class="pi pi-arrow-up text-xl text-green-500"></i>
                            </div>
                            <span class="text-900 line-height-3">Claire Smith
                                <span class="text-700">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad magnam reprehenderit saepe at voluptate eveniet, soluta blanditiis pariatur odio, earum quia consequatur, expedita velit repellendus natus est quae dicta. Est.</span>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>


            <div class="col-12 lg:col-6">
                <div class="border-round p-4">
                    <div class="text-xl text-900 font-medium mb-5">Aktivitäten</div>
                    <div class="mb-5 flex">
                        <div class="flex flex-column align-items-center" style="width:2rem">
                            <span class="bg-blue-500 text-0 flex align-items-center justify-content-center border-circle" style="min-width:2.5rem; min-height: 2.5rem">
                                <i class="pi pi-image"></i>
                            </span>
                            <div class="h-full bg-blue-500" style="width: 2px; min-height: 4rem"></div>
                        </div>
                        <div class="ml-5 surface-card shadow-2 border-round p-3 flex-auto">
                            <div class="mb-3">
                                <span class="text-900 font-medium inline-block mr-3">Jakob Johanson</span>
                                <span class="text-500 text-sm">vor 1 Minute</span>
                            </div>
                            <div class="line-height-3 text-700 mb-3">
                                Eu tincidunt tortor aliquam nulla facilisi cras fermentum. 
                                Sollicitudin nibh sit amet commodo nulla. Mauris in aliquam sem fringilla ut morbi.
                            </div>
                            <div class="text-500 flex align-items-center">
                                <i class="pi pi-heart mr-1"></i>
                                <span class="mr-3">0</span>
            
                                <i class="pi pi-comment mr-1"></i>
                                <span class="mr-3">1</span>
            
                                <i class="pi pi-eye mr-1"></i>
                                <span>24</span>
                            </div>
                        </div>
                    </div>
                    <div class="mb-5 flex">
                        <div class="flex flex-column align-items-center" style="width:2rem">
                            <span class="bg-orange-500 text-0 flex align-items-center justify-content-center border-circle" style="min-width:2.5rem; min-height: 2.5rem">
                                <i class="pi pi-image"></i>
                            </span>
                            <div class="h-full bg-orange-500" style="width: 2px; min-height: 4rem"></div>
                        </div>
                        <div class="ml-5 surface-card shadow-2 border-round p-3 flex-auto">
                            <div class="mb-3">
                                <span class="text-900 font-medium inline-block mr-3">Theresa Webber</span>
                                <span class="text-500 text-sm">vor 2 Stunden</span>
                            </div>
                            <div class="line-height-3 text-700 mb-3">
                                Purus sit amet volutpat consequat mauris. Pretium lectus quam id leo in vitae. 
                                Posuere sollicitudin aliquam ultrices sagittis orci a scelerisque purus semper.
                            </div>
                            <div class="text-500 flex align-items-center">
                                <i class="pi pi-heart mr-1"></i>
                                <span class="mr-3">26</span>
            
                                <i class="pi pi-comment mr-1"></i>
                                <span class="mr-3">6</span>
            
                                <i class="pi pi-eye mr-1"></i>
                                <span>673</span>
                            </div>
                        </div>
                    </div>
                    <div class="mb-5 flex">
                        <div class="flex flex-column align-items-center" style="width:2rem">
                            <span class="bg-purple-500 text-0 flex align-items-center justify-content-center border-circle" style="min-width:2.5rem; min-height: 2.5rem">
                                <i class="pi pi-image"></i>
                            </span>
                            <div class="h-full bg-purple-500" style="width: 2px; min-height: 4rem"></div>
                        </div>
                        <div class="ml-5 surface-card shadow-2 border-round p-3 flex-auto">
                            <div class="mb-3">
                                <span class="text-900 font-medium inline-block mr-3">Walter Schwarz</span>
                                <span class="text-500 text-sm">vor 4 Stunden</span>
                            </div>
                            <div class="line-height-3 text-700 mb-3">
                                Euismod in pellentesque massa placerat duis ultricies lacus. 
                                Vitae sapien pellentesque habitant morbi tristique senectus et netus et.
                            </div>
                            <div class="text-500 flex align-items-center">
                                <i class="pi pi-heart mr-1"></i>
                                <span class="mr-3">62</span>
            
                                <i class="pi pi-comment mr-1"></i>
                                <span class="mr-3">36</span>
            
                                <i class="pi pi-eye mr-1"></i>
                                <span>21</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>





                    <div class="col-12 lg:col-6">
                        <div class="surface-card shadow-2 border-round p-4">
                            <div class="flex align-items-center justify-content-between mb-3">
                                <div class="text-900 font-medium text-xl">Sales</div>
                                <div>
                                    <Button icon="pi pi-ellipsis-v" class="p-button-text p-button-plain p-button-rounded" @click="$refs.menu3.toggle($event)"></Button>
                                    <Menu ref="menu3" :popup="true" :model="items"></Menu>
                                </div>
                            </div>
                            <img src="images/blocks/chart/chart-line.svg" alt="Image" class="w-full">
                        </div>
                    </div>
                    <div class="col-12 lg:col-6">
                        <div class="surface-card shadow-2 border-round p-4">
                            <div class="flex align-items-center justify-content-between mb-3">
                                <div class="text-900 font-medium text-xl">Customers</div>
                                <div>
                                    <Button icon="pi pi-ellipsis-v" class="p-button-text p-button-plain p-button-rounded" @click="$refs.menu4.toggle($event)"></Button>
                                    <Menu ref="menu4" :popup="true" :model="items"></Menu>
                                </div>
                            </div>
                            <img src="images/blocks/chart/chart-bar.svg" alt="Image" class="w-full">
                        </div>
                    </div>
                

                <div class="col-12 lg:col-4 p-2">
                    <div class="shadow-2 surface-card border-round p-4 h-full">
                        <div class="flex align-items-start">
                            <div class="bg-blue-100 border-round inline-flex align-items-center justify-content-center" style="width:52px; height: 52px">
                                <img src="images/blocks/illustration/stats-illustration-1.svg" width="38" height="38" />
                            </div>
                            <div class="ml-3 flex-1">
                                <span class="block text-900 mb-1 text-xl font-medium">Tasks</span>
                                <p class="text-600 mt-0 mb-5 text-sm">Projects</p>
                                <div class="flex align-items-center justify-content-between">
                                    <span class="text-700">Completed</span>
                                    <span class="font-bold text-700">12/12</span>
                                </div>
                                <ul class="list-none p-0 m-0 flex align-content-center mt-2">
                                    <li style="height:7px; border-radius: 3px" class="flex-1 bg-blue-500 mr-2"></li>
                                    <li style="height:7px; border-radius: 3px" class="flex-1 bg-blue-500 mr-2"></li>
                                    <li style="height:7px; border-radius: 3px" class="flex-1 bg-blue-500 mr-2"></li>
                                    <li style="height:7px; border-radius: 3px" class="flex-1 bg-blue-500 mr-2"></li>
                                    <li style="height:7px; border-radius: 3px" class="flex-1 bg-blue-500"></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 lg:col-4 p-2">
                    <div class="shadow-2 surface-card border-round p-4 h-full">
                        <div class="flex align-items-start">
                            <div class="bg-blue-100 border-round inline-flex align-items-center justify-content-center" style="width:52px; height: 52px">
                                <img src="images/blocks/illustration/stats-illustration-2.svg" width="38" height="38" />
                            </div>
                            <div class="ml-3 flex-1">
                                <span class="block text-900 mb-1 text-xl font-medium">Email</span>
                                <p class="text-600 mt-0 mb-5 text-sm">Client Messages</p>
                                <div class="flex align-items-center justify-content-between">
                                    <span class="text-700">In Progress</span>
                                    <span class="font-bold text-700">4/12</span>
                                </div>
                                <ul class="list-none p-0 m-0 flex align-content-center mt-2">
                                    <li style="height:7px; border-radius: 3px" class="flex-1 bg-orange-500 mr-2"></li>
                                    <li style="height:7px; border-radius: 3px" class="flex-1 bg-orange-500 mr-2"></li>
                                    <li style="height:7px; border-radius: 3px" class="flex-1 bg-orange-500 mr-2"></li>
                                    <li style="height:7px; border-radius: 3px" class="flex-1 bg-orange-500 mr-2"></li>
                                    <li style="height:7px; border-radius: 3px" class="flex-1 bg-orange-500"></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 lg:col-4 p-2">
                    <div class="shadow-2 surface-card border-round p-4 h-full">
                        <div class="flex align-items-start">
                            <div class="bg-blue-100 border-round inline-flex align-items-center justify-content-center" style="width:52px; height: 52px">
                                <img src="images/blocks/illustration/stats-illustration-3.svg" width="38" height="38" />
                            </div>
                            <div class="ml-3 flex-1">
                                <span class="block text-900 mb-1 text-xl font-medium">Termine</span>
                                <p class="text-600 mt-0 mb-5 text-sm">Completed Tasks</p>
                                <div class="flex align-items-center justify-content-between">
                                    <span class="text-700">In Progress</span>
                                    <span class="font-bold text-700">8/12</span>
                                </div>
                                <ul class="list-none p-0 m-0 flex align-content-center mt-2">
                                    <li style="height:7px; border-radius: 3px" class="flex-1 bg-purple-500 mr-2"></li>
                                    <li style="height:7px; border-radius: 3px" class="flex-1 bg-purple-500 mr-2"></li>
                                    <li style="height:7px; border-radius: 3px" class="flex-1 bg-purple-500 mr-2"></li>
                                    <li style="height:7px; border-radius: 3px" class="flex-1 bg-purple-500 mr-2"></li>
                                    <li style="height:7px; border-radius: 3px" class="flex-1 bg-purple-500"></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

               


               
                </div>
            </div>
        </div>

    
    </div>    
</template>
<script>

export default {
name: 'AppStart',
    data() {
        return {
            items: [
                {
                    label:'ausblenden',
                    icon:'pi pi-fw pi-eye-slash',
                }
            ]
        }
    },
    computed:{
        main() {
            return this.$route.path === '/AppStart';
        }
    }
}
</script>